<template>
  <div>
    <a-tabs defaultActiveKey="1" @change="onTabChange" :size="isMobile ? 'small' : 'large'">
      <a-tab-pane tab="Cấu hình dịch vụ" key="1">
        <div class="pd-b-16">
          <a-upload name="file" :action="mockUploadLink" @change="handleDataImportFileChange" accept=".csv">
            <a-button>
              <a-icon type="upload" />
              Import KH
            </a-button>
          </a-upload>
        </div>
        <div class="pd-b-16">
          <a target="_blank" :href="linkFileSample">Download file mẫu</a>
        </div>
        <div class="pd-b-16">
          <a-table
            :columns="generateColumnsFromKey(dataImportColumns)"
            :data-source="dataImport"
            style="background: white"
          ></a-table>
        </div>
        <div class="pd-b-16">
          <a-button type="primary" :disabled="isUploading" @click="uploadDataImport">
            Upload
          </a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane tab="Danh sách cấu hình dịch vụ" key="2">
        <div class="header-select-data pd-b-16">
          <a-row :gutter="8">
            <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" :xxl="6">
              <a-date-picker @change="onChangeFromDate" style="width: 100%" placeholder="Từ ngày" format="DD/MM/YYYY" />
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" :xxl="6">
              <a-date-picker @change="onChangeToDate" style="width: 100%" placeholder="Đến ngày" format="DD/MM/YYYY" />
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" :xxl="6">
              <a-input
                style="width: 100%"
                v-model="serviceId"
                @change="checkVakidateInput"
                placeholder="Tìm service id"
                allowClear
              />
              <span class="error-input" v-if="errorServiceId">Chỉ được nhập số</span>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" :xxl="6">
              <a-input style="width: 100%" v-model="serviceName" placeholder="Tìm tên dịch vụ" allowClear />
            </a-col>
          </a-row>
          <a-row :gutter="8" style="margin-top: 14px">
            <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="4">
              <a-button :style="{ width: '100%' }" type="primary" icon="search" @click="handleViewData"
                >Xem dữ liệu</a-button
              >
            </a-col>
          </a-row>
        </div>
        <div class="result-select-data">
          <a-table
            :columns="dataSearchColumns"
            :loading="loading"
            :data-source="dataSearch"
            :pagination="pagination"
            style="background: white"
          ></a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
    <a-modal :visible="localVisible" @cancel="handleCancel" :footer="null">
      <div v-html="errorImport"></div>
    </a-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { getFactory } from '@/api'
import { isMobile } from 'mobile-device-detect'
import moment from 'moment'
const StynextServiceRepository = getFactory('stynextServiceSalary')
const StynextSalaryRepository = getFactory('stynextSalary')

export default {
  name: 'ToolGetOtp',
  data() {
    return {
      serviceId: undefined,
      serviceName: undefined,
      fromDate: null,
      toDate: null,
      errorServiceId: false,
      reg: /^[0-9]*$/,
      linkFileSample: 'https://docs.google.com/spreadsheets/d/15BBN3NGjK4nibNQ5ditspsJJfMyELo-Gk6EosaVyU9Q',
      dataImport: [],
      dataImportColumns: ['ViTri', 'DichVu', 'Level', 'ThuongDV', 'ThuongBonus'],
      mockUploadLink: process.env.VUE_APP_API_UPLOAD_CSV_DATA + '/mock-upload',
      isUploading: true,
      errorImport: '',
      localVisible: false,
      dataSearch: [],
      loading: false,
      pagination: { pageSizeOptions: ['10', '20', '50', '100', '1000'], showSizeChanger: true },
      dataSearchColumns: [
        {
          title: 'STT',
          dataIndex: 'stt'
        },
        {
          title: 'Tên salon',
          dataIndex: 'salonName'
        },
        {
          title: 'Id Dịch vụ',
          dataIndex: 'serviceId'
        },
        {
          title: 'Tên dịch vụ',
          dataIndex: 'serviceName'
        },
        {
          title: 'Vị trí',
          dataIndex: 'departmentName'
        },
        {
          title: 'Level',
          dataIndex: 'levelName'
        },
        {
          title: 'Thưởng dịch vụ',
          dataIndex: 'serviceSalary'
        },
        {
          title: 'Thưởng bonus',
          dataIndex: 'serviceBonus'
        },
        {
          title: 'Ngày tạo',
          dataIndex: 'createdDate'
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return isMobile
    }
  },
  methods: {
    handleCancel(e) {
      this.errorImport = ''
      this.localVisible = false
    },
    onTabChange() {},
    async handleDataImportFileChange(info) {
      if (info.file.status === 'done') {
        this.customerImportFile = info.file.originFileObj
        let data = await this.getDataFromExcelFile(info.file.originFileObj)

        data = data.slice(1)

        this.dataImport = data.map(row => {
          var item = {}
          this.dataImportColumns.forEach((key, index) => (item[key] = row[index]))
          return item
        })

        this.isUploading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    generateColumnsFromKey(keys) {
      if (!keys) return []
      let columns = keys.map(k => ({
        title: k,
        dataIndex: k,
        key: k
      }))
      return columns
    },
    async getDataFromExcelFile(fileBlob) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = function(e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          var first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
          var dataImport = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 })
          resolve(dataImport)
        }

        reader.onerror = error => {
          reject(error)
        }

        reader.readAsArrayBuffer(fileBlob)
      })
    },
    async uploadDataImport() {
      try {
        this.isUploading = true
        if (!this.dataImport || this.dataImport.length == 0) {
          return
        }
        var dataImport = []
        for (var i = 0; i < this.dataImport.length; i++) {
          var itemMap = {
            deparmentId: this.dataImport[i].ViTri,
            serviceId: this.dataImport[i].DichVu,
            levelId: this.dataImport[i].Level,
            serviceSalary: this.dataImport[i].ThuongDV,
            serviceBonus: this.dataImport[i].ThuongBonus
          }
          dataImport.push(itemMap)
        }
        await StynextServiceRepository.create(dataImport)
        this.isUploading = false
        this.$message.success('Upload file import thành công.')
        this.errorImport = ''
        this.localVisible = false
      } catch (e) {
        this.isUploading = false
        this.errorImport = e.response.data || ''
        this.localVisible = true
      }
    },
    onChangeFromDate(date, dateString) {
      this.fromDate = dateString
    },
    onChangeToDate(date, dateString) {
      this.toDate = dateString
    },
    checkVakidateInput() {
      if (this.serviceId != null && this.serviceId != undefined && this.serviceId != '') {
        if (this.reg.test(this.serviceId)) {
          this.errorServiceId = false
        } else {
          this.errorServiceId = true
        }
      } else {
        this.errorServiceId = false
      }
    },
    async handleViewData() {
      this.loading = true
      if (
        !this.errorServiceId &&
        ((this.serviceId != undefined && this.serviceId > 0) ||
          (this.serviceName != undefined && this.serviceName != '') ||
          (this.fromDate != undefined && this.fromDate != '') ||
          (this.toDate != undefined && this.toDate != ''))
      ) {
        let params = {
          serviceId: this.serviceId || 0,
          serviceName: this.serviceName || '',
          fromDate: moment(this.fromDate, 'DD/MM/YYYY').isValid()
            ? moment(this.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : '',
          toDate: moment(this.toDate, 'DD/MM/YYYY').isValid()
            ? moment(this.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : ''
        }
        this.dataSearch = await StynextSalaryRepository.GetServiceSalaryConfig(params)
      } else {
        this.dataSearch = []
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.error-input {
  color: red !important;
}

.pd-b-16 {
  padding-bottom: 16px;
}
</style>
